/*-----------------------------------------------------------------------------------

    Template Name: Educavo – Education React Template
    Description: Educavo – Education React Template is a clean and modern Education Template. It’s suitable for any university, online course, workshop, college, school, kindergarten, course hub or any kind of educational institution. This template includes different homepage demo with 30+ pages carefully designed blocks, which are easy to edit and customize to fit your needs. Template is perfect solution for the create of unique educational websites.
    Author: rs-theme
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

01. Theme default CSS
2. header
3. about
4. slider
5. footer
6. service
7. team
8. cta
9. project
10. testimonial
11. contact
12. carrer
13. blog
14.shop

-----------------------------------------------------------------------------------*/
@import 'variables';
@import 'mixins';
@import 'common';
@import 'overlay';
@import 'header';
@import 'btn';
@import 'about';
@import 'accordion';
@import 'degree';
@import 'slider';
@import 'banner';
@import 'footer';
@import 'services';
@import 'team';
@import 'team-details';
@import 'cta';
@import 'cart';
@import 'chooseus';
@import 'courses';
@import 'course_single';
@import 'categories';
@import 'project';
@import 'testimonial';
@import 'counter';
@import 'events';
@import 'video';
@import 'contact';
@import 'appointment_form';
@import 'blog';
@import 'brand';
@import 'newsletters';
@import 'faq';
@import 'gallery';
@import 'process';
@import 'widget';
@import 'progress';
@import 'skills';
@import 'feature';
@import 'publication';
@import 'facility';
@import 'callus';
@import 'pricing';
@import 'breadcrumb';
@import 'pagination';
@import 'shop';
@import 'technology';
@import 'scrollup';
@import 'error';
@import 'animations';
@import 'spacing';
@import 'responsive';
@import 'mobile-menu';
@import 'canvas-menu';
@import 'search-modal';
@import '../fonts/flaticon';
@import '../fonts/fontAwesome';


