.scrollup {    
    text-align: center;
    bottom: 40px;
    cursor: pointer;
    position: fixed;
    right: 20px;
    z-index: 999;
    border-radius: 50px 50px 4px 4px;
    i {
        display: block;
        background: $primaryColor;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        transition: $transition;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
        &:hover {
            opacity: 0.8;            
        }
    }
    &.orange-color{
        i{
            background: $primaryColor;
        }
    }
    &.purple-color{
        i{
           background: #787cf2; 
        }
    }
    &.yellow-color{
        i{
           background: #f4bf00; 
        }
    }
    &.green-color{
        i{
           background: #0c8b51; 
        }
    }
}