.rs-partner {
    .partner-item {
        a {
            img {
                max-width: 200px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50%;
            }
        }
    }
    &.style2{
        .partner-item {
            a {
                img {
                    max-width: 200px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    width: 50%;
                }
            }
        }
    }
}

.brand-title {
    // display: inline-block;
    // vertical-align: middle;
    color: #000000;
    font-size: 12px;
    text-align: center;
}